.currentQuestion{
    font-size: 1.2rem;
    font-family: DomaineDisplay-Medium;
}

@media screen and (min-width: 960px) {
    .currentQuestion{
        font-size: 1.6rem;
    }
}

@media screen and (min-width: 1280px) {
    .currentQuestion{
        font-size: 2rem;
    }
}