@font-face{
  font-family: "DomaineDisplay-Medium";
  src: url("/public/static/fonts/DomaineDisplayWeb-Medium.woff2");
}

@font-face{
  font-family: "DomaineDisplay-Italic";
  src: url("/public/static/fonts/DomaineDisplayWeb-RegularItalic.woff2");
}

@font-face{
  font-family: "UntitledSans-Light";
  src: url("/public/static/fonts/UntitledSansWeb-Light.woff");
}

@font-face{
  font-family: "UntitledSans-Medium";
  src: url("/public/static/fonts/DomaineDisplayWeb-Medium.woff2");
}

body{
  margin: 0;
}

.background-container {
    background-image: url('../../../public/static/images/buttons/lakeOpener.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;  
    width: 100vw;
}

.flex-container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-container h2{
  font-size: 2rem;
  color: white;
  margin-bottom: 0;
  font-family: "DomaineDisplay-Medium";
}

.content-container h6{
  margin: 0 30px 10px 30px;
}

@media screen and (min-width: 1024px) {
  .flex-container{
    display: block;
    position: relative;
    text-align: left;
  }

  .flex-container img{
    position: absolute;
    left: 50px;
    top: 25px;
  }

  .content-container{
    position: absolute;
    bottom: 180px;
    right: 70px;
    text-align: right;
  }

  .content-container h2{
    text-align: left;
  }

  .content-container h6{
    width: 32vw;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .content-container button{
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .content-container{
    right: 130px;
    bottom: 180px;
  }

  .content-container h6{
    width: 26vw;
  }
}

@media screen and (min-width: 1490px) {
  .content-container{
    right: 180px;
    bottom: 140px;
  }

  .content-container h2{
    font-size: 2.2rem;
  }

  .content-container h6{

  }
}